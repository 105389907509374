.theme--light.v-list-item--disabled {
  color: rgba(0, 0, 0, 0.38);
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: rgba(0, 0, 0, 0.87) !important;
}
.theme--light.v-list-item .v-list-item__mask {
  color: rgba(0, 0, 0, 0.38);
  background: #eeeeee;
}
.theme--light.v-list-item .v-list-item__subtitle,
.theme--light.v-list-item .v-list-item__action-text {
  color: rgba(0, 0, 0, 0.6);
}
.theme--light.v-list-item:hover::before {
  opacity: 0.04;
}
.theme--light.v-list-item:focus::before {
  opacity: 0.12;
}
.theme--light.v-list-item--active:hover::before, .theme--light.v-list-item--active::before {
  opacity: 0.12;
}
.theme--light.v-list-item--active:focus::before {
  opacity: 0.16;
}
.theme--light.v-list-item.v-list-item--highlighted::before {
  opacity: 0.16;
}

.theme--dark.v-list-item--disabled {
  color: rgba(255, 255, 255, 0.5);
}
.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #FFFFFF !important;
}
.theme--dark.v-list-item .v-list-item__mask {
  color: rgba(255, 255, 255, 0.5);
  background: #494949;
}
.theme--dark.v-list-item .v-list-item__subtitle,
.theme--dark.v-list-item .v-list-item__action-text {
  color: rgba(255, 255, 255, 0.7);
}
.theme--dark.v-list-item:hover::before {
  opacity: 0.08;
}
.theme--dark.v-list-item:focus::before {
  opacity: 0.24;
}
.theme--dark.v-list-item--active:hover::before, .theme--dark.v-list-item--active::before {
  opacity: 0.24;
}
.theme--dark.v-list-item--active:focus::before {
  opacity: 0.32;
}
.theme--dark.v-list-item.v-list-item--highlighted::before {
  opacity: 0.32;
}

.v-list-item {
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  letter-spacing: normal;
  min-height: 48px;
  outline: none;
  padding: 0 16px;
  position: relative;
  text-decoration: none;
}
.v-list-item--disabled {
  pointer-events: none;
}
.v-list-item--selectable {
  -webkit-user-select: auto;
     -moz-user-select: auto;
      -ms-user-select: auto;
          user-select: auto;
}
.v-list-item::after {
  content: "";
  min-height: inherit;
  font-size: 0;
}

.v-list-item__action {
  align-self: center;
  margin: 12px 0;
}
.v-list-item__action .v-input,
.v-list-item__action .v-input__control,
.v-list-item__action .v-input__slot,
.v-list-item__action .v-input--selection-controls__input {
  margin: 0 !important;
}
.v-list-item__action .v-input {
  padding: 0;
}
.v-list-item__action .v-input .v-messages {
  display: none;
}

.v-list-item__action-text {
  font-size: 0.75rem;
}

.v-list-item__avatar {
  align-self: center;
  justify-content: flex-start;
  margin-bottom: 8px;
  margin-top: 8px;
}
.v-list-item__avatar.v-list-item__avatar--horizontal {
  margin-bottom: 8px;
  margin-top: 8px;
}
.v-application--is-ltr .v-list-item__avatar.v-list-item__avatar--horizontal:first-child {
  margin-left: -16px;
}
.v-application--is-rtl .v-list-item__avatar.v-list-item__avatar--horizontal:first-child {
  margin-right: -16px;
}
.v-application--is-ltr .v-list-item__avatar.v-list-item__avatar--horizontal:last-child {
  margin-left: -16px;
}
.v-application--is-rtl .v-list-item__avatar.v-list-item__avatar--horizontal:last-child {
  margin-right: -16px;
}

.v-list-item__content {
  align-items: center;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  overflow: hidden;
  padding: 12px 0;
}
.v-list-item__content > * {
  line-height: 1.1;
  flex: 1 0 100%;
}
.v-list-item__content > *:not(:last-child) {
  margin-bottom: 2px;
}

.v-list-item__icon {
  align-self: flex-start;
  margin: 16px 0;
}

.v-application--is-ltr .v-list-item__action:last-of-type:not(:only-child),
.v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child),
.v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child) {
  margin-left: 16px;
}
.v-application--is-rtl .v-list-item__action:last-of-type:not(:only-child),
.v-application--is-rtl .v-list-item__avatar:last-of-type:not(:only-child),
.v-application--is-rtl .v-list-item__icon:last-of-type:not(:only-child) {
  margin-right: 16px;
}

.v-application--is-ltr .v-list-item__avatar:first-child {
  margin-right: 16px;
}
.v-application--is-rtl .v-list-item__avatar:first-child {
  margin-left: 16px;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 32px;
}
.v-application--is-rtl .v-list-item__action:first-child,
.v-application--is-rtl .v-list-item__icon:first-child {
  margin-left: 32px;
}

.v-list-item__action,
.v-list-item__avatar,
.v-list-item__icon {
  display: inline-flex;
  min-width: 24px;
}

.v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle {
  line-height: 1.2;
}

.v-list-item__title,
.v-list-item__subtitle {
  flex: 1 1 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.v-list-item__title {
  align-self: center;
  font-size: 1rem;
}
.v-list-item__title > .v-badge {
  margin-top: 16px;
}

.v-list-item__subtitle {
  font-size: 0.875rem;
}

.v-list-item--dense,
.v-list--dense .v-list-item {
  min-height: 40px;
}
.v-list-item--dense .v-list-item__icon,
.v-list--dense .v-list-item .v-list-item__icon {
  height: 24px;
  margin-top: 8px;
  margin-bottom: 8px;
}
.v-list-item--dense .v-list-item__content,
.v-list--dense .v-list-item .v-list-item__content {
  padding: 8px 0;
}
.v-list-item--dense .v-list-item__title,
.v-list-item--dense .v-list-item__subtitle,
.v-list--dense .v-list-item .v-list-item__title,
.v-list--dense .v-list-item .v-list-item__subtitle {
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1rem;
}
.v-list-item--dense.v-list-item--two-line,
.v-list--dense .v-list-item.v-list-item--two-line {
  min-height: 60px;
}
.v-list-item--dense.v-list-item--three-line,
.v-list--dense .v-list-item.v-list-item--three-line {
  min-height: 76px;
}

.v-list-item--link {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-list-item--link:before {
  background-color: currentColor;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.v-list .v-list-item--active {
  color: inherit;
}
.v-list .v-list-item--active .v-icon {
  color: inherit;
}

.v-list-item__action--stack {
  align-items: flex-end;
  align-self: stretch;
  justify-content: space-between;
  white-space: nowrap;
  flex-direction: column;
}

.v-list--two-line .v-list-item .v-list-item__avatar:not(.v-list-item__avatar--horizontal),
.v-list--two-line .v-list-item .v-list-item__icon,
.v-list--three-line .v-list-item .v-list-item__avatar:not(.v-list-item__avatar--horizontal),
.v-list--three-line .v-list-item .v-list-item__icon,
.v-list-item--two-line .v-list-item__avatar:not(.v-list-item__avatar--horizontal),
.v-list-item--two-line .v-list-item__icon,
.v-list-item--three-line .v-list-item__avatar:not(.v-list-item__avatar--horizontal),
.v-list-item--three-line .v-list-item__icon {
  margin-bottom: 16px;
  margin-top: 16px;
}

.v-list--two-line .v-list-item,
.v-list-item--two-line {
  min-height: 64px;
}
.v-list--two-line .v-list-item .v-list-item__icon,
.v-list-item--two-line .v-list-item__icon {
  margin-bottom: 32px;
}

.v-list--three-line .v-list-item,
.v-list-item--three-line {
  min-height: 88px;
}
.v-list--three-line .v-list-item .v-list-item__avatar,
.v-list--three-line .v-list-item .v-list-item__action,
.v-list-item--three-line .v-list-item__avatar,
.v-list-item--three-line .v-list-item__action {
  align-self: flex-start;
  margin-top: 16px;
  margin-bottom: 16px;
}
.v-list--three-line .v-list-item .v-list-item__content,
.v-list-item--three-line .v-list-item__content {
  align-self: stretch;
}
.v-list--three-line .v-list-item .v-list-item__subtitle,
.v-list-item--three-line .v-list-item__subtitle {
  white-space: initial;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}