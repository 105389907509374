.theme--light.v-calendar-category .v-calendar-category__column,
.theme--light.v-calendar-category .v-calendar-category__column-header {
  border-right: #e0e0e0 1px solid;
}

.theme--dark.v-calendar-category .v-calendar-category__column,
.theme--dark.v-calendar-category .v-calendar-category__column-header {
  border-right: #9e9e9e 1px solid;
}

.v-calendar-category .v-calendar-category__category {
  text-align: center;
}
.v-calendar-category .v-calendar-daily__day-container .v-calendar-category__columns {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}
.v-calendar-category .v-calendar-category__columns {
  display: flex;
}
.v-calendar-category .v-calendar-category__columns .v-calendar-category__column,
.v-calendar-category .v-calendar-category__columns .v-calendar-category__column-header {
  flex: 1 1 auto;
  width: 0;
  position: relative;
}