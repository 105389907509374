.theme--light.v-alert .v-alert--prominent .v-alert__icon:after {
  background: rgba(0, 0, 0, 0.12);
}

.theme--dark.v-alert .v-alert--prominent .v-alert__icon:after {
  background: rgba(255, 255, 255, 0.12);
}

.v-sheet.v-alert {
  border-radius: 4px;
}
.v-sheet.v-alert:not(.v-sheet--outlined) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.v-sheet.v-alert.v-sheet--shaped {
  border-radius: 24px 4px;
}

.v-alert {
  display: block;
  font-size: 16px;
  margin-bottom: 16px;
  padding: 16px;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-alert:not(.v-sheet--tile) {
  border-radius: 4px;
}
.v-application--is-ltr .v-alert > .v-icon,
.v-application--is-ltr .v-alert > .v-alert__content {
  margin-right: 16px;
}
.v-application--is-rtl .v-alert > .v-icon,
.v-application--is-rtl .v-alert > .v-alert__content {
  margin-left: 16px;
}
.v-application--is-ltr .v-alert > .v-icon + .v-alert__content {
  margin-right: 0;
}
.v-application--is-rtl .v-alert > .v-icon + .v-alert__content {
  margin-left: 0;
}
.v-application--is-ltr .v-alert > .v-alert__content + .v-icon {
  margin-right: 0;
}
.v-application--is-rtl .v-alert > .v-alert__content + .v-icon {
  margin-left: 0;
}

.v-alert__border {
  border-style: solid;
  border-width: 4px;
  content: "";
  position: absolute;
}
.v-alert__border:not(.v-alert__border--has-color) {
  opacity: 0.26;
}
.v-alert__border--left, .v-alert__border--right {
  bottom: 0;
  top: 0;
}
.v-alert__border--bottom, .v-alert__border--top {
  left: 0;
  right: 0;
}
.v-alert__border--bottom {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
}
.v-application--is-ltr .v-alert__border--left {
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  left: 0;
}
.v-application--is-rtl .v-alert__border--left {
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  right: 0;
}
.v-application--is-ltr .v-alert__border--right {
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  right: 0;
}
.v-application--is-rtl .v-alert__border--right {
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  left: 0;
}
.v-alert__border--top {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  top: 0;
}

.v-alert__content {
  flex: 1 1 auto;
}

.v-application--is-ltr .v-alert__dismissible {
  margin: -16px -8px -16px 8px;
}
.v-application--is-rtl .v-alert__dismissible {
  margin: -16px 8px -16px -8px;
}

.v-alert__icon {
  align-self: flex-start;
  border-radius: 50%;
  height: 24px;
  min-width: 24px;
  position: relative;
}
.v-application--is-ltr .v-alert__icon {
  margin-right: 16px;
}
.v-application--is-rtl .v-alert__icon {
  margin-left: 16px;
}
.v-alert__icon.v-icon {
  font-size: 24px;
}

.v-alert__wrapper {
  align-items: center;
  border-radius: inherit;
  display: flex;
}

.v-alert--dense {
  padding-top: 8px;
  padding-bottom: 8px;
}
.v-alert--dense .v-alert__border {
  border-width: medium;
}

.v-alert--outlined {
  background: transparent !important;
  border: thin solid currentColor !important;
}
.v-alert--outlined .v-alert__icon {
  color: inherit !important;
}

.v-alert--prominent .v-alert__icon {
  align-self: center;
  height: 48px;
  min-width: 48px;
}
.v-alert--prominent .v-alert__icon:after {
  background: currentColor !important;
  border-radius: 50%;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0.16;
  position: absolute;
  right: 0;
  top: 0;
}
.v-alert--prominent .v-alert__icon.v-icon {
  font-size: 32px;
}

.v-alert--text {
  background: transparent !important;
}
.v-alert--text:before {
  background-color: currentColor;
  border-radius: inherit;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0.12;
  position: absolute;
  pointer-events: none;
  right: 0;
  top: 0;
}